import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store';
import { RoadmapItem } from 'utils/types';
import { addViewsRoadmap, deleteRoadmapView, getRoadmapView, getRoadmapViews } from './viewsRoadmap-api';

interface ViewsRoadmapState {
    viewsRoadmap: { id: number, name: string }[]
    getViewsRoadmapLoading?: boolean
    roadmapViewId: number | null
    getRoadmapViewLoading?: boolean
    roadmapView: RoadmapItem[]
    addViewsRoadmapLoading?: boolean
    deleteRoadmapViewLoading?: boolean
}

const initialState: ViewsRoadmapState = {
    viewsRoadmap: [],
    roadmapViewId: null,
    roadmapView: [],
}

const viewsRoadmapSlice = createSlice({
    name: 'viewsRoadmap',
    initialState,
    reducers: {
        deleteRoadmapViewAction: (state, action: PayloadAction<number>) => {
            state.viewsRoadmap = state.viewsRoadmap.filter(el => el.id !== action.payload)
            state.roadmapViewId = Array.isArray(state.viewsRoadmap) && state.viewsRoadmap.length > 0 ? state.viewsRoadmap[state.viewsRoadmap.length - 1].id : null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRoadmapViews.pending, (state) => {
                state.getViewsRoadmapLoading = true;
            })
            .addCase(getRoadmapViews.fulfilled, (state, action) => {
                state.getViewsRoadmapLoading = false;
                state.viewsRoadmap = action.payload
                state.roadmapViewId = Array.isArray(action.payload) && action.payload.length > 0 ? action.payload[action.payload.length - 1].id : null
            })
            .addCase(getRoadmapViews.rejected, (state, action) => {
                state.getViewsRoadmapLoading = false;
            })
            .addCase(getRoadmapView.pending, (state) => {
                state.getRoadmapViewLoading = true;
            })
            .addCase(getRoadmapView.fulfilled, (state, action) => {
                state.getRoadmapViewLoading = false;
                state.roadmapView = action.payload.map((el: { id: number, data: RoadmapItem }) => el.data)
            })
            .addCase(getRoadmapView.rejected, (state, action) => {
                state.getRoadmapViewLoading = false;
            })
            .addCase(addViewsRoadmap.pending, (state) => {
                state.addViewsRoadmapLoading = true;
            })
            .addCase(addViewsRoadmap.fulfilled, (state, action) => {
                state.addViewsRoadmapLoading = false;
                state.roadmapViewId = action.payload.id
                state.roadmapView = action.payload.roadmapItems.map((el: { id: number, data: RoadmapItem }) => el.data)
            })
            .addCase(addViewsRoadmap.rejected, (state, action) => {
                state.addViewsRoadmapLoading = false;
            })
            .addCase(deleteRoadmapView.pending, (state) => {
                state.deleteRoadmapViewLoading = true;
            })
            .addCase(deleteRoadmapView.fulfilled, (state, action) => {
                state.deleteRoadmapViewLoading = false;
            })
            .addCase(deleteRoadmapView.rejected, (state, action) => {
                state.deleteRoadmapViewLoading = false;
            })
    },
})

export const roadmapViewsSelector = (store: RootState) => store.viewsRoadmap.viewsRoadmap;
export const roadmapViewIdSelector = (store: RootState) => store.viewsRoadmap.roadmapViewId;
export const roadmapViewSelector = (store: RootState) => store.viewsRoadmap.roadmapView;
export const getViewsRoadmapLoadingSelector = (store: RootState) => store.viewsRoadmap.getViewsRoadmapLoading
export const getRoadmapViewLoadingSelector = (store: RootState) => store.viewsRoadmap.getRoadmapViewLoading
export const addViewsRoadmapLoadingSelector = (store: RootState) => store.viewsRoadmap.addViewsRoadmapLoading
export const deleteRoadmapViewLoadingSelector = (store: RootState) => store.viewsRoadmap.deleteRoadmapViewLoading

export const {
    deleteRoadmapViewAction
} = viewsRoadmapSlice.actions;

export default viewsRoadmapSlice;
