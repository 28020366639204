
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store';
import { initiativeGradingApi } from './initiativeGrading-api';
import { IrelevantPoint, Insight, InitiativeScore, JiraStory, TrackingStory } from 'utils/types';

interface InitiativeGradingState {
    score: number,
    allIrrelevantPoints: IrelevantPoint[],
    insights: Insight[],
    insightId: number | null,
    missingScores: InitiativeScore[];
    overallProgress: number;
    stories: TrackingStory[]
    jiraStories: JiraStory[]
}

const initialState: InitiativeGradingState = {
    score: 0,
    allIrrelevantPoints: [],
    insights: [],
    insightId: null,
    missingScores: [],
    overallProgress: 0,
    stories: [],
    jiraStories: [],
}

const initiativeGradingSlice = createSlice({
    name: 'initiativeGrading',
    initialState,
    reducers: {
        updateIrrelevantPoints: (state, action: PayloadAction<{ id: number, content: string, irrelevanceId: number, title: string }>) => {
            const { id, content, irrelevanceId, title } = action.payload
            state.allIrrelevantPoints = [...state.allIrrelevantPoints, {
                id, content, irrelevanceId, title,
            }]

            state.insights = state.insights.map(insight => ({
                ...insight,
                points: insight.points ? insight.points?.map(point => {
                    if (point.id === id) {
                        return {
                            ...point,
                            isRelevant: false
                        }
                    } else {
                        return point
                    }
                }) : null,
                subsections: insight.subsections ? insight.subsections.map(subsection => ({
                    ...subsection,
                    points: subsection.points.map(point => {
                        if (point.id === id) {
                            return {
                                ...point,
                                isRelevant: false
                            }
                        } else {
                            return point
                        }
                    })
                })) : null
            }))
        },
        deleteIrrelevantPointAction: (state, action: PayloadAction<number>) => {
            state.allIrrelevantPoints = state.allIrrelevantPoints.filter(el => el.irrelevanceId !== action.payload)
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            initiativeGradingApi.endpoints.getInitiativeScore.matchFulfilled,
            (state, { payload }) => {
                state.score = payload.score
                state.insights = payload.insight.content
                state.insightId = payload.insight.id
                state.missingScores = payload.missingScores
            },
        ),
            builder.addMatcher(
                initiativeGradingApi.endpoints.getAllIrrelevantPoints.matchFulfilled,
                (state, { payload }) => {
                    state.allIrrelevantPoints = payload
                }
            ),
            builder.addMatcher(
                initiativeGradingApi.endpoints.getInitiativeTracking.matchFulfilled,
                (state, { payload }) => {
                    state.overallProgress = payload.overallProgress
                    state.stories = payload.stories
                    state.jiraStories = payload.jiraStories
                }
            ),
            builder.addMatcher(
                initiativeGradingApi.endpoints.reEvaluateScore.matchFulfilled,
                (state, { payload }) => {
                    state.score = payload.score
                    state.insights = payload.insight.content
                    state.insightId = payload.insight.id
                    state.missingScores = payload.missingScores
                }
            )
    },
})

export const initiativeScoreSelector = (store: RootState) => store.initiativeGrading.score;
export const initiativeInsightsSelector = (store: RootState) => store.initiativeGrading.insights;
export const allIrrelevantPointsSelector = (store: RootState) => store.initiativeGrading.allIrrelevantPoints;
export const insightIdSelector = (store: RootState) => store.initiativeGrading.insightId;
export const missingScoresSelector = (store: RootState) => store.initiativeGrading.missingScores;
export const overallProgressSelector = (store: RootState) => store.initiativeGrading.overallProgress;
export const storiesSelector = (store: RootState) => store.initiativeGrading.stories;
export const jiraStoriesSelector = (store: RootState) => store.initiativeGrading.jiraStories;

export const {
    updateIrrelevantPoints,
    deleteIrrelevantPointAction
} = initiativeGradingSlice.actions;

export default initiativeGradingSlice;
