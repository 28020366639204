import { FC } from 'react'
import { SidebarMenuConfig, SidebarMenuItem } from 'utils/types'
import classNames from 'classnames/bind';
import styles from './sidebarGroup.module.scss';
import { hasShowMenuItemPermission } from '../..';
import MenuItem from '../menuItem';
import Flexbox from 'components/Flexbox';
import FeatureFlagEnabled from 'components/FeatureFlagEnabled';

const classes = classNames.bind(styles);

interface SidebarGroupProps {
    groupName: string,
    menu: SidebarMenuConfig,
    menuItems: SidebarMenuItem[],
    collapsed: boolean
}

const SidebarGroup: FC<SidebarGroupProps> = ({ groupName, menu, menuItems, collapsed }) => {
    // const [visibleItems, setVisibleItems] = useState<SidebarMenuItem[]>(menuItems);
    // const [isExpanded, setIsExpanded] = useState(false);

    // const viewsLoading = useSelector(viewsLoadingSelector);

    // useEffect(() => {
    //     if (menu.maxLength && menuItems.length) {
    //         setVisibleItems(menuItems.slice(0, menu.maxLength));
    //     }
    // }, [menu.maxLength, menuItems]);

    // const handleToggleAllItems = () => {
    //     setIsExpanded(prevState => {
    //         const newState = !prevState;
    //         setVisibleItems(newState ? menuItems : menuItems.slice(0, menu.maxLength));
    //         return newState;
    //     });
    // };

    return (
        <Flexbox vertical className={classes('gap-4')}>
            {/* <Flexbox align justify={collapsed} justifyBetween={!collapsed}>
                <CustomTyphography type='secondary' variant='caption'>{groupName.toUpperCase()}</CustomTyphography>
                {
                    !collapsed && menu.actionButton && !!menuItems.length ? <menu.actionButton /> : null
                }
            </Flexbox> */}
            {/* {
                !collapsed && !viewsLoading && menu.createButton && menuItems.length === 0 && (<menu.createButton />)
            } */}

            {/* {
                collapsed && groupName === SidebarGroupType.VIEWS ? (
                    <MenuPopover
                        items={menuItems}
                        actionButton={(props: any) =>
                            <Flexbox {...props} justify align>
                                <Typography>
                                    {menuItems.length}
                                </Typography>
                            </Flexbox>
                        }
                        actions={
                            {
                                edit: handleEditView,
                                delete: showDeleteConfirmation
                            }
                        }
                    />
            } */}

            <Flexbox vertical className={classes('gap-1'
                // , { viewsGroupContainer: groupName === SidebarGroupType.VIEWS }
            )
            }>
                {/* {viewsLoading && groupName === SidebarGroupType.VIEWS && <Flexbox align justify><Loader /></Flexbox>} */}
                {menuItems.map((item, i) => (
                    hasShowMenuItemPermission(item) ? (
                        item.featureFlag ? (
                            <FeatureFlagEnabled key={i} flagKey={item.featureFlag}>
                                <MenuItem
                                    isCollapsed={collapsed}
                                    item={item}
                                />
                            </FeatureFlagEnabled>
                        ) : (
                            <MenuItem
                                key={i}
                                isCollapsed={collapsed}
                                item={item}
                            />
                        )
                    ) : null
                ))}
            </Flexbox>

            {/* {!collapsed && !viewsLoading && menu.maxLength && menuItems.length > menu.maxLength && (
                <CommonButton
                    className={classes('w-full')}
                    onClick={handleToggleAllItems}
                >
                    {isExpanded ? 'Hide Views' : 'See All Views'}
                </CommonButton>
            )} */}
        </Flexbox>
    )
}

export default SidebarGroup