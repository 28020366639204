import Flexbox from 'components/Flexbox'
import classNames from 'classnames/bind';
import styles from '../assistant.module.scss'
import CustomTyphography from 'components/CustomTyphography';
import { Box, Loader } from 'components';
import { FC } from 'react';
import { isHtmlLike, isMarkdown, snakeToTitleCase } from 'utils/string';
import Markdown from 'components/Markdown';

const classes = classNames.bind(styles);

const getFormattedDate = (value: number) => {
    const date = new Date(value);

    if (!isNaN(date.getTime())) {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }
}

interface KBSourcesSidebarProps {
    title: string
    complexFields?: { [key: string]: string | number | { [key: string]: string }[] | string[] }[]
    loading?: boolean
}

const renderValue = (value: any): React.ReactNode => {
    if (typeof value === 'string') {
        if (isHtmlLike(value)) {
            return <div dangerouslySetInnerHTML={{ __html: value }} />;
        } else if (isMarkdown(value)) {
            return <Markdown text={value} />;
        } else {
            return <CustomTyphography className={classes('text-end')}>{value}</CustomTyphography>;
        }
    } else if (typeof value === 'number') {
        return <CustomTyphography className={classes('text-end')}>{value.toString()}</CustomTyphography>;
    } else if (Array.isArray(value)) {
        return (
            <ul>
                {Array.isArray(value) && value.map((item, index) => (
                    <li key={index} className={classes('sourcesSidebar-listItem')}>{renderValue(item)}</li>
                ))}
            </ul>
        );
    } else if (typeof value === 'object' && value !== null) {
        return Object.entries(value).map(([key, val]) => (
            <Flexbox key={key}>
                <CustomTyphography type='primary' className={classes('sourcesSidebar-key', 'pr-2')}>{snakeToTitleCase(key)}:</CustomTyphography>
                {renderValue(val)}
            </Flexbox>
        ));
    } else {
        return null;
    }
};

const KBSourcesSidebar: FC<KBSourcesSidebarProps> = ({
    title,
    complexFields,
    loading
}) => {
    return (
        <Flexbox vertical fullWidth className={classes('sourcesSidebarContainer', 'gap-6')}>
            <Flexbox fullWidth align justifyBetween className={classes('pb-4', 'sourcesSidebar-titleContainer')}>
                <CustomTyphography type='primary' className={classes('sourcesSidebar-title')}>{title}</CustomTyphography>
            </Flexbox>
            {
                loading ? <Flexbox align justify><Loader /></Flexbox> : <Flexbox vertical fullWidth className={classes('gap-6', 'sourcesSidebar-mainContent')}>
                    <Flexbox vertical className={classes('gap-6')}>
                        <Flexbox vertical fullWidth className={classes('gap-5', 'sourcesSidebar-group')}>
                            {
                                !!complexFields?.length && complexFields.map((entry, entryIndex) => {
                                    const entries = Object.entries(entry).filter(entry => !!entry[1] && !entry[0].includes('id') && !entry[0].includes('index'))

                                    const startEntries = entries.filter(entry => entry[0] === 'chunk' || entry[0] === 'description');
                                    const lists = entries.filter(entry => Array.isArray(entry[1]));
                                    const endEntries = entries.filter(entry => entry[0] === 'createdDate' || entry[0] === 'target_date' || entry[0] === 'lastModifiedDate');
                                    const middleEntries = entries.filter(entry => !startEntries.includes(entry) && !endEntries.includes(entry) && !lists.includes(entry));

                                    return <Box className={classes('sourcesSidebar-item')} key={entryIndex}>
                                        {startEntries.map(entry => (
                                            <Flexbox key={entry[0]} vertical className={classes('p-3', 'gap-1')}>
                                                <CustomTyphography type='primary' className={classes('sourcesSidebar-key')}>{snakeToTitleCase(entry[0])}:</CustomTyphography>
                                                <Flexbox justifyBetween align fullWidth className={classes('sourcesSidebar-groupItem', 'sourcesSidebar-startItem', 'gap-4')}>
                                                    {renderValue(entry[1])}
                                                </Flexbox>
                                            </Flexbox>
                                        ))}
                                        {middleEntries.map(entry => (
                                            <Flexbox key={entry[0]} vertical align className={classes('p-3', 'gap-5')}>
                                                <Flexbox justifyBetween align fullWidth className={classes('sourcesSidebar-groupItem', 'gap-4')}>
                                                    <CustomTyphography type='primary' className={classes('sourcesSidebar-key')}>{snakeToTitleCase(entry[0])}:</CustomTyphography>
                                                    {renderValue(entry[1])}
                                                </Flexbox>
                                            </Flexbox>
                                        ))}
                                        {lists.map(entry => (
                                            <Flexbox key={entry[0]} vertical className={classes('p-3', 'gap-1')}>
                                                <CustomTyphography type='primary' className={classes('sourcesSidebar-key')}>{snakeToTitleCase(entry[0])}:</CustomTyphography>
                                                <Flexbox vertical fullWidth className={classes('sourcesSidebar-groupItem')}>
                                                    <ul>
                                                        {Array.isArray(entry[1]) && entry[1].map((item, index) => (
                                                            <li key={index} className={classes('sourcesSidebar-listItem')}>{renderValue(item)}</li>
                                                        ))}
                                                    </ul>
                                                </Flexbox>
                                            </Flexbox>
                                        ))}
                                        {endEntries.map(entry => (
                                            <Flexbox key={entry[0]} vertical align className={classes('p-3', 'gap-5')}>
                                                <Flexbox justifyBetween align fullWidth className={classes('sourcesSidebar-groupItem')}>
                                                    <CustomTyphography className={classes('sourcesSidebar-dateKey')} type='primary'>{snakeToTitleCase(entry[0])}:</CustomTyphography>
                                                    <CustomTyphography className={classes('sourcesSidebar-dateValue')}>{getFormattedDate(entry[1] as number)}</CustomTyphography>
                                                </Flexbox>
                                            </Flexbox>
                                        ))}
                                    </Box>
                                })
                            }
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            }
        </Flexbox>
    )
}

export default KBSourcesSidebar