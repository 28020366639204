import { Flexbox, Link, Typography } from 'components'
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../../../initiative.module.scss';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import AgGridTable, { AgColumn, ColumnTypes } from 'components/AgGridTable';
import { GridApi, ICellRendererParams } from 'ag-grid-enterprise';
import { JiraStory } from 'utils/types';
import { GetDetailRowDataParams, GridReadyEvent } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import { jiraStoriesSelector } from 'store/initiativeGrading-slice';
import { workspaceIntegrationSelector } from 'store/integrations';
import { getWorkspaceIntegrations } from 'pages/Integrations/integrations.api';

const classes = classNames.bind(styles);

interface StoriesProgressTableProps {

}

const JiraStoriesProgressTable: FC<StoriesProgressTableProps> = () => {
    const dispatch = useDispatch();

    const jiraStories = useSelector(jiraStoriesSelector);
    const workspaceIntegrations = useSelector(workspaceIntegrationSelector);

    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const getJiraUrl = (jiraId: string) => {
        const integration = workspaceIntegrations.find(integration => integration.name === 'JIRA');
        if (integration) {
            const baseUrl = integration.integrationProperties.url;
            return `${baseUrl}/browse/${jiraId}`;
        }
        return '#'
    }

    const LinkCellRenderer = useMemo(() => ({ value, key }: { value: string, key: string }) => {
        const jiraUrl = getJiraUrl(key)

        return <Link
            className='linked-cell'
            to={jiraUrl}
            target='_blank'
        >
            {value}
        </Link>
    }, [workspaceIntegrations])

    const columns: AgColumn[] = useMemo(() => [
        {
            headerName: 'Title',
            field: 'title',
            minWidth: 200,
            sortable: true,
            cellRendererSelector: (params: ICellRendererParams) => {
                const hasGrouping = params.api.getRowGroupColumns().length > 0
                if (hasGrouping) {
                    return {
                        component: LinkCellRenderer,
                        params: { value: params?.value || '', key: params.data?.key },
                    }
                } else {
                    return { component: 'agGroupCellRenderer' }
                }
            },
            cellRendererParams: {
                suppressCount: true,
                innerRenderer: (params: ICellRendererParams) => {
                    const jiraUrl = getJiraUrl(params.data?.key)

                    return <Link
                        className='linked-cell grouped-title'
                        to={jiraUrl}
                        target='_blank'
                    >
                        {params?.value || ''}
                    </Link>
                },
                suppressDoubleClickExpand: true,
                suppressEnterExpand: true,
            },
            enableRowGroup: false,
            enablePivot: false,
            suppressMovable: true,
            lockVisible: true,
            wrapText: true,
            autoHeight: true,
        },
        {
            colType: ColumnTypes.Progress,
            headerName: 'Progress of Subtasks',
            field: 'completion',
            minWidth: 200,
            sortable: true,
            enableRowGroup: false,
            enablePivot: false,
            suppressMovable: true,
        },
        {
            headerName: 'Status',
            field: 'currentStatus',
            minWidth: 120,
            sortable: true,
            enableRowGroup: false,
            enablePivot: false,
            suppressMovable: true,
        },
        {
            headerName: 'Issue Type',
            field: 'type',
            minWidth: 120,
            sortable: true,
            enableRowGroup: false,
            enablePivot: false,
            suppressMovable: true,
        },
        {
            colType: ColumnTypes.TimeAgo,
            headerName: 'Time in Status',
            field: 'lastStatusChangeTime',
            minWidth: 150,
            sortable: true,
            enableRowGroup: false,
            enablePivot: false,
            suppressMovable: true,
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'Due Date',
            field: 'dueDate',
            sortable: true,
            enableRowGroup: false,
            enablePivot: false,
            suppressMovable: true,
        },
    ], [workspaceIntegrations])

    const detailCellRendererParams = useMemo(() => ({
        detailGridOptions: {
            columnDefs: [
                {
                    headerName: 'Title',
                    field: 'title',
                    minWidth: 200,
                    flex: 1,
                    cellRenderer: (params: ICellRendererParams) => {
                        const jiraUrl = getJiraUrl(params.data?.key)
                        return (
                            <Link
                                className='linked-cell grouped-title'
                                to={jiraUrl}
                                target='_blank'
                            >
                                {params.value}
                            </Link>
                        );
                    },
                    wrapText: true,
                    autoHeight: true,
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    minWidth: 150,
                    flex: 1
                },
                {
                    headerName: 'Issue Type',
                    field: 'type',
                    minWidth: 150,
                    flex: 1
                },
                {
                    colType: ColumnTypes.TimeAgo,
                    headerName: 'Time in Status',
                    field: 'lastStatusChangeDate',
                    minWidth: 150,
                    flex: 1
                },
            ] as AgColumn[]
        },
        getDetailRowData: (params: GetDetailRowDataParams) => params.successCallback(params.data?.tasks),
    }), [workspaceIntegrations])

    useEffect(() => {
        dispatch(getWorkspaceIntegrations());
    }, [])

    return (
        <Flexbox fullWidth vertical className={classes('storiesProgressContainer')}>
            <Flexbox className={classes('headerContainer')}>
                <Typography variant='h4' className={classes('headerTitle')}>Progress Breakdown</Typography>
                <Flexbox>
                    {gridApi && <ExportButton api={gridApi} />}
                </Flexbox>
            </Flexbox>
            <AgGridTable
                data={jiraStories}
                columns={columns}
                onGridReady={onGridReady}
                exportFileName={'Progress Breakdown'}
                domLayout='autoHeight'
                showSidebar={false}
                masterDetail
                isRowMaster={(params: JiraStory) => !!params.tasks?.length}
                detailCellRendererParams={detailCellRendererParams}
                rowGroupPanelShow={'never'}
            />
        </Flexbox>
    )
}

export default JiraStoriesProgressTable;