import { ActionsMenu, AddButton, ConfirmationDialog, Flexbox, MultilineInput, RichTextEditor, Table } from 'components'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setTemplateData, updateTemplateAction } from 'store/initiativeTemplates-slice'
import { InitiativeTemplate, Template, moveItemDirection } from 'utils/types'
import styles from '../styles.module.scss'
import classNames from 'classnames/bind';
import { useEditInitiativeTemplateMutation } from 'store/initiativeTemplates-api'
import { useWorkspaceId } from 'utils/hooks'
import { useParams } from 'react-router-dom'
import { TableHeader, TableRowProps } from 'components/Table'
import { Typography } from '@mui/material'
import { ArrowDownwardIcon, ArrowUpwardIcon, DeleteIcon, MoveDownIcon, MoveUpIcon } from 'components/icons'
const classes = classNames.bind(styles);

interface TemplateFieldProps {
    item: Template
    itemIndex: number
    template: InitiativeTemplate
}

interface ConversationSample {
    assistant: string,
    user: string,
    actions: string
}

const conversationSampleHeader: TableHeader<ConversationSample>[] = [
    {
        id: 'user',
        text: 'User',
    },
    {
        id: 'assistant',
        text: 'Assistant',
    },
    {
        id: 'actions',
        text: '',
    }
];

const TemplateField: FC<TemplateFieldProps> = ({ item, itemIndex, template }) => {
    const dispatch = useDispatch()
    const workspaceId = useWorkspaceId();
    const params = useParams()

    const templateId = params.id
    const [editTemplate] = useEditInitiativeTemplateMutation()

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [deletingExample, setDeletingExample] = useState<{ tempIndex: number, exIndex: number }>();
    const [newExampleRow, setNewExampleRow] = useState<{ user: string, assistant: string }>({ user: '', assistant: '' })
    const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] = useState<boolean>(false)

    const onFieldChange = (name: string, value: string, index: number, exIndex?: number) => {
        dispatch(updateTemplateAction({ templateIndex: index, key: name, value, exIndex }))
    }

    const handleDeleteExample = async () => {
        if (deletingExample) {
            const { id, ...rest } = template

            const newData = {
                ...rest,
                template: rest.template.map((template, index) => {
                    if (index === deletingExample.tempIndex) {
                        return {
                            ...template,
                            examples: [...template.examples.slice(0, deletingExample.exIndex), ...template.examples.slice(deletingExample.exIndex + 1)]
                        }
                    } else {
                        return template
                    }
                })
            }

            try {
                await editTemplate({ workspaceId, data: newData, templateId })
                onCancelDelete()
                dispatch(setTemplateData({ id, ...newData }))
            } catch (err) {
                console.log(err);
            }
        }
    }

    const onChangeAddingExampleField = (name: string, value: string,) => {
        setNewExampleRow(prev => ({ ...prev, [name]: value }))
    }

    const handleAddExample = async (tempIndex: number) => {
        const { id, ...rest } = template

        const newData = {
            ...rest,
            template: rest.template.map((template, index) => {
                if (index === tempIndex) {
                    return {
                        ...template,
                        examples: [newExampleRow, ...template.examples]
                    }
                } else {
                    return template
                }
            })
        }

        try {
            await editTemplate({ workspaceId, data: newData, templateId })
            setNewExampleRow({ user: '', assistant: '' })
            dispatch(setTemplateData({ id, ...newData }))
        } catch (err) {
            console.log(err);
        }
    }

    const onCancelDelete = () => {
        setOpenConfirmation(false)
        setDeletingExample(undefined)
    }

    const onEditTemplate = async () => {
        const { id, ...rest } = template

        try {
            await editTemplate({ workspaceId, data: rest, templateId })
        } catch (err) {
            console.log(err);
        }
    }

    const onCancelDeleteTemplate = () => {
        setShowDeleteTemplateDialog(false)
    }

    const handleDeleteTemplate = async () => {
        const { id, ...rest } = template

        const newData = {
            ...rest,
            template: [...rest.template.slice(0, itemIndex), ...rest.template.slice(itemIndex + 1)]
        }

        try {
            await editTemplate({ workspaceId, data: newData, templateId })
            dispatch(setTemplateData({ id, ...newData }))
            setShowDeleteTemplateDialog(false)
        } catch (err) {
            console.log(err);
        }
    }

    const moveTemplate = (direction: moveItemDirection) => {
        const { id, ...rest } = template

        const templateData = [...rest.template];

        let newIndex;
        switch (direction) {
            case moveItemDirection.down:
                newIndex = itemIndex + 1;
                break;
            case moveItemDirection.up:
                newIndex = itemIndex - 1;
                break;
            case moveItemDirection.top:
                newIndex = 0;
                break;
            case moveItemDirection.bottom:
                newIndex = templateData.length - 1;
                break;
            default:
                return;
        }

        if (newIndex < 0 || newIndex >= templateData.length || newIndex === itemIndex) {
            return;
        }

        const [removedItem] = templateData.splice(itemIndex, 1);

        templateData.splice(newIndex, 0, removedItem);

        dispatch(setTemplateData({ ...template, template: templateData }))

        try {
            editTemplate({ workspaceId, data: { ...rest, template: templateData }, templateId })
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Flexbox justifyBetween className={classes('templateItem')}>
            <Flexbox vertical fullWidth className={classes('gap-2')}>
                <MultilineInput
                    value={item.title}
                    onChange={(e) => onFieldChange('title', e.target.value, itemIndex)}
                    label='Title'
                    placeholder='Title'
                    onBlur={onEditTemplate}
                />
                <MultilineInput
                    value={item.description}
                    onChange={(e) => onFieldChange('description', e.target.value, itemIndex)}
                    label='Description'
                    placeholder='Description'
                    onBlur={onEditTemplate}
                />
                <RichTextEditor
                    value={item.instructions}
                    onChange={(value) => onFieldChange('instructions', value, itemIndex)}
                    label='instructions'
                    placeholder='instructions'
                    files={[]}
                    onBlur={onEditTemplate}
                />
                <Flexbox vertical className={classes('gap-4')}>
                    <Typography sx={{
                        fontSize: '18px',
                        fontWeight: 500
                    }}>Examples</Typography>
                    <Table
                        className={'templateItem-table'}
                        header={conversationSampleHeader}
                        height='auto'
                        data={
                            [
                                {
                                    data: [
                                        <Flexbox>
                                            <MultilineInput
                                                value={newExampleRow.user}
                                                onChange={(e) => onChangeAddingExampleField('user', e.target.value)}
                                                placeholder='User'
                                            />
                                        </Flexbox>,
                                        <Flexbox>
                                            <MultilineInput
                                                value={newExampleRow.assistant}
                                                onChange={(e) => onChangeAddingExampleField('assistant', e.target.value)}
                                                placeholder='Assistant'
                                            />
                                        </Flexbox>,
                                        <Flexbox>
                                            <AddButton
                                                onClick={() => handleAddExample(itemIndex)}
                                                active={!!newExampleRow.user && !!newExampleRow.assistant}
                                                disabled={!newExampleRow.user || !newExampleRow.assistant}
                                            />
                                        </Flexbox>
                                    ]
                                },
                                ...item.examples.map((example, exIndex) => {
                                    const row: TableRowProps = {
                                        data: [
                                            <Flexbox>
                                                <MultilineInput
                                                    value={example.user}
                                                    onChange={(e) => onFieldChange('user', e.target.value, itemIndex, exIndex)}
                                                    placeholder='User'
                                                    onBlur={onEditTemplate}
                                                />
                                            </Flexbox>,
                                            <Flexbox>
                                                <MultilineInput
                                                    value={example.assistant}
                                                    onChange={(e) => onFieldChange('assistant', e.target.value, itemIndex, exIndex)}
                                                    placeholder='Assistant'
                                                    onBlur={onEditTemplate}
                                                />
                                            </Flexbox>,
                                            <Flexbox>
                                                <ActionsMenu className={classes('actionMenu')}
                                                    buttonItems={[
                                                        {
                                                            label: 'Delete',
                                                            action: () => {
                                                                setOpenConfirmation(true)
                                                                setDeletingExample({ tempIndex: itemIndex, exIndex })
                                                            },
                                                            type: 'red'
                                                        }
                                                    ]}
                                                />
                                            </Flexbox>
                                        ],
                                    }
                                    return row
                                })
                            ]
                        }
                    />
                </Flexbox>
                <ConfirmationDialog
                    open={openConfirmation}
                    onClose={onCancelDelete}
                    onConfirm={handleDeleteExample}
                    confirmButtonStyle='danger'
                    title='Delete this Example?'
                >
                    <Flexbox>You're about to permanently delete this Example.</Flexbox>
                </ConfirmationDialog>
                <ConfirmationDialog
                    open={showDeleteTemplateDialog}
                    onClose={onCancelDeleteTemplate}
                    onConfirm={handleDeleteTemplate}
                    confirmButtonStyle='danger'
                    title='Delete this Template?'
                >
                    <Flexbox>You're about to permanently delete this Template.</Flexbox>
                </ConfirmationDialog>
            </Flexbox>
            <ActionsMenu
                buttonItems={[
                    {
                        label: 'Move to top',
                        action: () => moveTemplate(moveItemDirection.top),
                        disabled: itemIndex === 0,
                        icon: <ArrowUpwardIcon />,
                    },
                    {
                        label: 'Move up',
                        action: () => moveTemplate(moveItemDirection.up),
                        disabled: itemIndex === 0,
                        icon: <MoveUpIcon />,
                    },
                    {
                        label: 'Move down',
                        action: () => moveTemplate(moveItemDirection.down),
                        disabled: itemIndex === template.template.length - 1,
                        icon: <MoveDownIcon />,
                    },
                    {
                        label: 'Move to bottom',
                        action: () => moveTemplate(moveItemDirection.bottom),
                        disabled: itemIndex === template.template.length - 1,
                        icon: <ArrowDownwardIcon />,
                    },
                    {
                        label: 'Delete',
                        type: 'red',
                        action: () => {
                            setShowDeleteTemplateDialog(true);
                        },
                        icon: <DeleteIcon />,
                    },
                ]}
            />
        </Flexbox>
    )
}

export default TemplateField