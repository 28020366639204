import { CustomTyphography, Flexbox, Loader, Typography } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import EmptyState from 'common/emptyState';
import { DataScience, Flag } from 'components/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Actions, hasPermission } from 'utils/permissions';
import Header from './Header';
import { GridApi } from 'ag-grid-enterprise';
import AgGridTable, { AgColumn, ColumnTypes } from 'components/AgGridTable';
import { useDispatch, useSelector } from 'react-redux';
import { CellClickedEvent, GetDetailRowDataParams, GridReadyEvent, ICellRendererParams, SortChangedEvent } from 'ag-grid-community';
import { useSearchParams } from 'react-router-dom';
import SidePanelDrawer from 'components/SidePanelDrawer';
import { getRoadmapView, getRoadmapViews } from 'store/viewsRoadmap-api';
import { getRoadmapViewLoadingSelector, getViewsRoadmapLoadingSelector, roadmapViewIdSelector, roadmapViewSelector } from 'store/viewsRoadmap-slice';
import { RoadmapItem } from 'utils/types';
import AddSourcePopup from './AddSourcePopup';
import Markdown from 'components/Markdown';

const classes = classNames.bind(styles);

const PlanningAnalysis = () => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const roadmapViewId = useSelector(roadmapViewIdSelector)
    const roadmapView = useSelector(roadmapViewSelector)
    const getViewsRoadmapLoading = useSelector(getViewsRoadmapLoadingSelector)
    const getRoadmapViewLoading = useSelector(getRoadmapViewLoadingSelector)

    const [openAddSourcePopup, setOpenAddSourcePopup] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState(roadmapView)
    const [orderBy, setOrderBy] = useState<keyof RoadmapItem | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if (hasPermission(Actions.create)) {
            buttons.push({
                onClick: () => { setOpenAddSourcePopup(true) },
                text: 'Add Roadmap Planning Source',
            })
        }
        return buttons
    }, [])

    const columns: AgColumn[] = useMemo(() => [
        {
            headerName: 'Title',
            field: 'title',
            minWidth: 300,
            sortable: true,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell',
            cellRendererSelector: (params: ICellRendererParams) => {
                const hasGrouping = params.api.getRowGroupColumns().length > 0
                if (hasGrouping) {
                    return { component: undefined }
                } else {
                    return { component: 'agGroupCellRenderer' }
                }
            },
        },
        {
            colType: ColumnTypes.SimpleSelect,
            headerName: 'Owner',
            field: 'owner',
            minWidth: 150,
            sortable: true,
        },
        {
            headerName: 'Feedback / Analyses',
            field: 'feedback_count',
            minWidth: 150,
            sortable: true,
            valueFormatter: (params: any) => {
                return `${params?.value} Analyses`;
            },
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group && params.node.field !== 'feedback_count') {
                    return null
                } else {
                    return (
                        <Flexbox align className={classes('h-full')}>
                            <Flexbox align className={classes('analysisBox')}>
                                <Flag />
                                <CustomTyphography>{`${params.value} action items`}</CustomTyphography>
                            </Flexbox>
                        </Flexbox>
                    );
                }
            },
            canExpand: true
        },
    ], []);

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                headerHeight: 0,
                columnDefs: [
                    {
                        headerName: 'Name',
                        field: 'title',
                        minWidth: 200,
                        flex: 1,
                        cellRenderer: (params: ICellRendererParams) => {
                            return <SidePanelDrawer
                                actionElement={
                                    (props: any) => (
                                        <Flexbox align className={classes('h-full', 'w-full', 'cursor-pointer')} {...props}>
                                            {`${params.value}`}
                                        </Flexbox>
                                    )
                                }
                            >
                                <Flexbox vertical className={classes('analysisItem', 'gap-4')}>
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: 700
                                        }}
                                    >
                                        {params.value}
                                    </Typography>
                                    <Flexbox vertical>
                                        <ul>
                                            {params.data.original_value && <li>
                                                <span className={classes('analysisItem-subtitle')}>Original text:</span><Markdown text={params.data.original_value} />
                                            </li>}
                                            <li><span className={classes('analysisItem-subtitle')}>Feedback:</span> <Markdown text={params.data.feedback} /></li>
                                            <li><span className={classes('analysisItem-subtitle')}>Suggestions:</span><Markdown text={params.data.suggestions} /></li>
                                            <li>
                                                <CustomTyphography><span className={classes('analysisItem-subtitle')}>Examples:</span></CustomTyphography>
                                                <Markdown text={params.data.examples} />
                                            </li>
                                        </ul>
                                    </Flexbox>
                                </Flexbox>
                            </SidePanelDrawer>
                        }
                    }
                ] as AgColumn[]
            },
            getDetailRowData: (params: GetDetailRowDataParams) => {
                const data = Object.values(params.data?.analysis)
                return params.successCallback(data)
            },
        }
    }, [roadmapView])

    useEffect(() => {
        dispatch(getRoadmapViews())
    }, [])

    useEffect(() => {
        if (roadmapViewId) {
            dispatch(getRoadmapView(roadmapViewId))
        }
    }, [roadmapViewId])

    useEffect(() => {
        if (roadmapView) {
            const filteredItems = roadmapView.filter((item: RoadmapItem) => item.title?.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()))
            setFilteredData(filteredItems)
        }
    }, [searchValue, roadmapView])

    const updateQueryPreference = useCallback((value: string) => {
        setSearchValue(value);
    }, [])

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    useEffect(() => {
        const order = searchParams.get('order');
        const orderBy = searchParams.get('orderBy')
        const query = searchParams.get('query')

        if (order) { setOrder(order as 'asc' | 'desc') }
        if (orderBy) { setOrderBy(orderBy as keyof RoadmapItem) }
        if (query) { setSearchValue(query) }
    }, [searchParams])

    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });
    }, [searchParams])

    const onCellClicked = (params: CellClickedEvent) => {
        const colId = params.column.getColId()

        if (colId === 'feedback_count' || colId === 'title') {
            params.node.setExpanded(!params.node.expanded);
        }
    };

    return (
        <Flexbox fullWidth vertical>
            {
                (getViewsRoadmapLoading || getRoadmapViewLoading) ? (
                    <Flexbox fullWidth fullHeight align justify>
                        <Loader disableShrink />
                    </Flexbox>
                ) : roadmapView.length === 0 ? (
                    <EmptyState
                        icon={<DataScience />}
                        title='Planning Analyses'
                        titleSmall={buttonItemsEmptyState.length ? 'Start creating yours by adding items' : undefined}
                        buttonItems={buttonItemsEmptyState}
                    />
                ) : (
                    <>
                        <Header
                            gridApi={gridApi}
                            updateQueryPreference={updateQueryPreference}
                            searchValue={searchValue}
                            emptyState={Array.isArray(roadmapView) && roadmapView.length === 0}
                            totalRows={roadmapView.length}
                            pendingRows={roadmapView.filter(item => item.feedback_count > 0).length}
                            roadmapViewId={roadmapViewId}
                        />
                        <Flexbox fullHeight className={classes('')}>
                            <AgGridTable
                                data={filteredData}
                                columns={columns}
                                onGridReady={onGridReady}
                                exportFileName={'Planning Analyses'}
                                onSortChanged={onSortChanged}
                                order={order}
                                orderBy={orderBy}
                                masterDetail
                                detailCellRendererParams={detailCellRendererParams}
                                isRowMaster={(params: RoadmapItem) => params.analysis !== null}
                                onCellClicked={onCellClicked}
                            />
                        </Flexbox>
                    </>
                )
            }
            <AddSourcePopup open={openAddSourcePopup} handleClosePopup={() => setOpenAddSourcePopup(false)} />
        </Flexbox>
    )
}

export default PlanningAnalysis