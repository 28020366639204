import { Button, Flexbox, Loader } from 'components'
import styles from '../styles.module.scss'
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import classNames from 'classnames/bind';
import { useWorkspaceId } from 'utils/hooks';
import { useEditInitiativeTemplateMutation, useLazyGetInitiativeTemplateQuery } from 'store/initiativeTemplates-api';
import { useDispatch, useSelector } from 'react-redux';
import { currentTemplateSelector, setTemplateData } from 'store/initiativeTemplates-slice';
import { useParams } from 'react-router-dom';
import TemplateField from './TemplateField';
import { useEffect, useMemo } from 'react';
import EmptyState from 'common/emptyState';
import { DataScience, PlusIcon } from 'components/icons';
const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);

const Template = () => {
    const workspaceId = useWorkspaceId();
    const dispatch = useDispatch();
    const params = useParams();
    const templateId = params.id

    const [getInitiativeTemplate, { isLoading }] = useLazyGetInitiativeTemplateQuery()
    const [editTemplate] = useEditInitiativeTemplateMutation()

    useEffect(() => {
        getInitiativeTemplate({ workspaceId, templateId })

        return () => {
            dispatch(setTemplateData(null))
        }
    }, [templateId])

    const template = useSelector(currentTemplateSelector)

    const handleAddTemplate = () => {
        if (template) {
            const { id, ...rest } = template

            const newData = {
                ...rest,
                template: [
                    {
                        title: '',
                        description: '',
                        instructions: '',
                        examples: []
                    },
                    ...rest.template
                ]
            }

            dispatch(setTemplateData({ id, ...newData }))

            try {
                editTemplate({ workspaceId, data: newData, templateId })
            } catch (err) {
                console.log(err);
            }
        }
    }

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        buttons.push({
            onClick: () => handleAddTemplate,
            text: 'Add Template',
        })

        return buttons
    }, [template])

    return (
        <>
            <Flexbox vertical fullWidth className={classes('mainContainer')}>
                <Flexbox vertical className={classesInfo('headerContainer')}>
                    <Flexbox className={classesInfo('headerInfoTop')}>
                        <Flexbox className={classesInfo('headerTitle')}>{template?.name}</Flexbox>
                    </Flexbox>
                </Flexbox>
                {
                    isLoading ? (
                        <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox>
                    ) :
                        template?.template.length === 0 ? (
                            <EmptyState
                                icon={<DataScience />}
                                title={template?.name || 'Initiative Template'}
                                buttonItems={buttonItemsEmptyState}
                            />
                        ) : (
                            <Flexbox vertical justifyStart className={classes('templateContainer', 'gap-2')}>
                                <Button sx={{
                                    width: 'fit-content'
                                }} onClick={handleAddTemplate} startIcon={<PlusIcon />}>
                                    Add Template
                                </Button>
                                <Flexbox vertical className={classes('gap-6')}>
                                    {
                                        template && template.template.map((item, index) => (
                                            <TemplateField item={item} key={index} itemIndex={index} template={template} />
                                        ))
                                    }
                                </Flexbox>
                            </Flexbox>
                        )
                }
            </Flexbox>
        </>
    )
}

export default Template