import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';

import request from 'utils/request';

export const addViewsRoadmap = createAsyncThunk(
    'viewsRoadmap/addViewsRoadmap',
    async (data: { source: File, name: string }, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/views/roadmap`;
        const formData = new FormData();

        formData.append('source', data.source);
        formData.append('name', data.name);

        const response = await request.post(
            url,
            formData,
            {
                timeout: 1000 * 300,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data;
    }
);

export const getRoadmapView = createAsyncThunk(
    'viewsRoadmap/getRoadmapView',
    async (roadmapViewId: number, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/views/roadmap/${roadmapViewId}`;
        const response = await request.get(url);

        return response.data;
    }
);

export const getRoadmapViews = createAsyncThunk(
    'viewsRoadmap/getRoadmapViews',
    async (_, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/views/roadmap`;
        const response = await request.get(url);

        return response.data;
    }
);

export const deleteRoadmapView = createAsyncThunk(
    'viewsRoadmap/deleteRoadmapView',
    async (roadmapViewId: number, { getState }) => {
        const storeState = getState() as RootState;

        const url = `/workspaces/${storeState.user.workspace.id}/views/roadmap/${roadmapViewId}`;
        const response = await request.delete(url);

        return response.data;
    }
);