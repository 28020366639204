import { Button, Flexbox } from 'components'
import { capitalize } from 'utils/string'
import { AssistantMessage, PRDMainType } from 'utils/types'
import { FC } from 'react'
import { returnMainContent } from 'pages/Assistant'
import classNames from 'classnames/bind'
import styles from '../../assistant.module.scss';

const classes = classNames.bind(styles);

interface PRDSectionButtonsProps {
    message: AssistantMessage
    handlePRDSectionPreview: (key: string, message: AssistantMessage) => void
    prdPreviewId?: string | number
    currentSection?: string
}

const PRDSectionButtons: FC<PRDSectionButtonsProps> = ({ message, handlePRDSectionPreview, prdPreviewId, currentSection }) => {
    return (
        <Flexbox vertical>
            <ul className={classes('prdSectionList')}>
                {message.text.content && Object.keys(returnMainContent(message.text.content as PRDMainType)).map((key) => {
                    return (
                        <li key={key}>
                            <Button
                                onClick={() => handlePRDSectionPreview(key, message)}
                                sx={{
                                    color: '#025B62',
                                    background: message.id === prdPreviewId && key === currentSection ? 'rgba(2, 91, 98, 0.08)' : '#F7F7F8',
                                    borderRadius: '8px',
                                    fontSize: '16px',
                                }}
                            >
                                {capitalize(key)}
                            </Button>
                        </li>
                    )
                })}
            </ul>
        </Flexbox>
    )
}

export default PRDSectionButtons