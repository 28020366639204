import classNames from 'classnames/bind';
import { Button, Flexbox } from 'components';
import { NotFoundIcon } from 'components/icons';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { getFirstAvailableMenuItem } from 'common/mainLayout/Sidebar/menuItems';
const classes = classNames.bind(styles);

const NotFound = () => {
    const navigate = useNavigate()

    const backToHome = () => {
        navigate(`/${getFirstAvailableMenuItem().url}`)
    }

    return (
        <Flexbox fullWidth vertical align className={classes('notFoundContainer')}>
            <Flexbox className={classes('icon')}>
                <NotFoundIcon />
            </Flexbox>
            <Flexbox className={classes('titleLarge')}>
                Not Found
            </Flexbox>
            <Flexbox className={classes('titleSmall')}>
                Page doesn’t exist or you don’t have access
            </Flexbox>
            <Button variant='contained' onClick={backToHome}>
                Back to home
            </Button>
        </Flexbox>
    )
}

export default NotFound