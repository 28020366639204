import { JiraField } from './types'


export const getStringTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'string')
}

export const getPriorityTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'priority')
}

export const getDateTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'datetime' || field.schema.type === 'date')
}

export const getUserTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'user')
}

export const getTeamTypes = (fields: JiraField[]) => {
    return fields.filter(field => field.schema.type === 'team')
}
