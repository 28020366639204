import { CustomTyphography, Flexbox, IconButton, Loader, Tooltip } from 'components'
import styles from './prdScorSidePanel.module.scss'
import classNames from 'classnames/bind';
import { RegenerateIcon } from 'components/icons';
import { FC, useEffect, useMemo } from 'react';
import { useWorkspaceId } from 'utils/hooks';
import { useLazyGetInitiativeScoreQuery, useLazyReEvaluateScoreQuery } from 'store/initiativeGrading-api';
import { useDispatch, useSelector } from 'react-redux';
import { initiativeInsightsSelector, initiativeScoreSelector, missingScoresSelector } from 'store/initiativeGrading-slice';
import { InitiativeScoreKey } from 'utils/types';
import { feedbackPercentage } from 'pages/Initiatives/components/tabs/execution/constants';
import InsightPoint from './InsightPoint';

const classes = classNames.bind(styles);

interface PRDScoreSidePanelProps {
    initiativeId: number
}

const PRDScoreSidePanel: FC<PRDScoreSidePanelProps> = ({ initiativeId }) => {
    const workspaceId = useWorkspaceId();

    const [getInitiativeScore, { isLoading: scoreDataLoading }] = useLazyGetInitiativeScoreQuery()
    const [reEvaluateScore, { isLoading: isReEvaluateScoreLoading }] = useLazyReEvaluateScoreQuery()

    const score = useSelector(initiativeScoreSelector)
    const insights = useSelector(initiativeInsightsSelector)
    const missingScores = useSelector(missingScoresSelector)

    useEffect(() => {
        getInitiativeScore({ workspaceId, id: initiativeId })
    }, [initiativeId])

    const missingScoresArray = useMemo(() => Object.entries(missingScores).map(([title, score]) => ({
        title: InitiativeScoreKey[title as keyof typeof InitiativeScoreKey],
        score: Number(score),
    })), [missingScores]);

    const handleReEvaluateScore = async () => {
        try {
            await reEvaluateScore({ workspaceId, id: initiativeId })
        } catch (err) {
            console.log(err, 'err');
        }
    }

    return (
        <Flexbox vertical align className={classes('prdSidePanel', 'gap-4')}>
            <Flexbox align justifyBetween fullWidth>
                <Flexbox className={classes('titleContainer')}>
                    <CustomTyphography className={classes('gradingTitle')}>Overall PRD Score:</CustomTyphography>
                    <CustomTyphography className={classes('gradingValue')}>{score} %</CustomTyphography>
                </Flexbox>

                <Flexbox justifyEnd align>
                    {isReEvaluateScoreLoading && <Loader size={22} />}
                    <Tooltip title={'Re-evaluate PRD Score'}>
                        <IconButton
                            onClick={handleReEvaluateScore}
                            disabled={isReEvaluateScoreLoading}
                            className={classes('reEvaluateBtn')}
                        >
                            <RegenerateIcon />
                        </IconButton>
                    </Tooltip>
                </Flexbox>
            </Flexbox>
            <Flexbox vertical fullWidth className={classes('prdSidePanel-mainContent', 'gap-4')}>
                {scoreDataLoading ? (
                    <Flexbox align justify className={classes('mt-4')}>
                        <Loader />
                    </Flexbox>
                ) : (
                    <>
                        <Flexbox vertical className={classes('gap-2', 'w-full', 'pb-6', 'missingScoresBox')}>
                            {
                                missingScoresArray.map((misScore, misScoreIdx) => {
                                    const scoreBgColor = feedbackPercentage.find(el => Math.abs(misScore.score) >= el.percentage[0] && Math.abs(misScore.score) <= el.percentage[1])?.bgColor;

                                    return (
                                        <Flexbox align key={`${misScoreIdx}-${misScore.score}`} className={classes('gap-2')}>
                                            <Flexbox justify align className={classes('scoreBox', scoreBgColor)}>
                                                -{misScore.score}%
                                            </Flexbox>
                                            <CustomTyphography type='primary'>{misScore.title}</CustomTyphography>
                                        </Flexbox>
                                    )
                                })
                            }
                        </Flexbox>
                        {
                            !!insights && (
                                <Flexbox vertical align className={classes('gap-4')}>
                                    <CustomTyphography type='primary' className={classes('gradingTitle')}>AI Feedback</CustomTyphography>
                                    <Flexbox vertical className={classes('gap-6', 'w-full', 'insightsBox')}>
                                        {
                                            insights.map((insight, insightIdx) => {
                                                return (
                                                    <Flexbox vertical key={insightIdx} className={classes('gap-2')}>
                                                        <CustomTyphography className={classes('insightTitle')}>{insight.title}</CustomTyphography>
                                                        <Flexbox vertical className={classes('gap-4')}>
                                                            {
                                                                insight.points && (
                                                                    <>
                                                                        {insight.points.filter(el => el.isRelevant).map(point => (
                                                                            <InsightPoint point={point} key={point.id} />
                                                                        ))}
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                insight.subsections && (
                                                                    <Flexbox vertical className={classes('subsectionsContainer')}>
                                                                        {insight.subsections.map((subsection, sIndex) => (
                                                                            <Flexbox vertical key={`${subsection.title}_${sIndex}`} className={classes('subsectionsBox', 'gap-4')}>
                                                                                <CustomTyphography className={classes('subsectionTitle')}>{subsection.title}</CustomTyphography>
                                                                                {
                                                                                    subsection.points.filter(el => el.isRelevant).map(point =>
                                                                                        <InsightPoint point={point} key={point.id} />
                                                                                    )
                                                                                }
                                                                            </Flexbox>
                                                                        ))}
                                                                    </Flexbox>
                                                                )
                                                            }
                                                        </Flexbox>
                                                    </Flexbox>
                                                )
                                            })
                                        }
                                    </Flexbox>
                                </Flexbox>
                            )
                        }
                    </>
                )}
            </Flexbox>
        </Flexbox >
    )
}

export default PRDScoreSidePanel