import { FC } from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface FeatureFlagEnabledProps {
  flagKey: string;
  children: React.ReactNode;
}

const FeatureFlagEnabled: FC<FeatureFlagEnabledProps> = ({ flagKey, children }) => {
  const flagEnabled = useFeatureFlagEnabled(flagKey);

  if (flagEnabled) {
    return <>{children}</>;
  }

  return null;
};

export default FeatureFlagEnabled;
