import { useEffect, useRef, useState } from 'react';
import store from 'store';

export const useDebounce = <T extends unknown>(
    initialValue: T,
    timeout: number = 500
): [T, T, (value: T) => void] => {
    const [value, setValue] = useState<T>(initialValue);
    const [debounceValue, setDebounceValue] = useState<T>(initialValue);
    const timer = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            setDebounceValue(value);
        }, timeout);

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [value]);
    return [value, debounceValue, setValue];
};

export const useWorkspaceId = () => store.getState().user.workspace.id