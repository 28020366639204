import { GridApi, ProcessCellForExportParams } from 'ag-grid-community';
import ActionsButton from 'components/ActionsButton'
import { DownloadIcon, ExportIcon } from 'components/icons';
import { FC } from 'react';

interface ExportButtonProps {
  api: GridApi
  className?: string
  text?: string,
  formatExportedCellValue?: (colId: string, value: any, formattedValue: any) => any
}

const ExportButton: FC<ExportButtonProps> = ({ api, className, text = 'Export', formatExportedCellValue }) => {
    const exportParams = {
        processCellCallback(params: ProcessCellForExportParams) {
            let value = params.value
            const colId = params.column.getColId()
            const formattedValue = params.formatValue(params.value)

            return formatExportedCellValue ? formatExportedCellValue(colId, value, formattedValue) : formattedValue
        },
    };

    const onExportDataAsCsv = () => {
        api.exportDataAsCsv(exportParams);
    }

    const onExportDataAsExcel = () => {
        api.exportDataAsExcel(exportParams);
    }

    return (
        <ActionsButton
            buttonItems={[
                { label: 'Download CSV file', action: onExportDataAsCsv, icon: <DownloadIcon /> },
                { label: 'Export to Excel', action: onExportDataAsExcel, icon: <ExportIcon /> },
            ]}
            className={`AgExport ${className}`}
            btnClassName='AgExport-btn'
            variant='text'
            buttonText={text}
        />
    );
}

export default ExportButton;
