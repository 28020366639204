import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const initiativeTemplatesApi = createApi({
    reducerPath: 'initiativeTemplates.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['initiativeTemplates'],
    endpoints: builder => ({
        getInitiativeTemplates: builder.query({
            query: ({ workspaceId }) => `/workspaces/${workspaceId}/initiatives/templates`,
        }),
        getInitiativeTemplate: builder.query({
            query: ({ workspaceId, templateId }) => `/workspaces/${workspaceId}/initiatives/templates/${templateId}`,
        }),
        editInitiativeTemplate: builder.mutation({
            query: ({ workspaceId, data, templateId }) => {
                return {
                    url: `/workspaces/${workspaceId}/initiatives/templates/${templateId}`,
                    method: 'PUT',
                    body: data
                };
            },
        })
    })
});

export const {
    useEditInitiativeTemplateMutation,
    useGetInitiativeTemplatesQuery,
    useLazyGetInitiativeTemplatesQuery,
    useGetInitiativeTemplateQuery,
    useLazyGetInitiativeTemplateQuery
} = initiativeTemplatesApi