import { ConfirmationDialog, CustomSnackbar, Flexbox, SearchField } from 'components';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ChangeEvent, FC, useState } from 'react';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import { GridApi } from 'ag-grid-enterprise';
import { useSearchParams } from 'react-router-dom';
import ActionsMenu, { ButtonItem } from 'components/ActionsMenu';
import { Actions, hasPermission } from 'utils/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRoadmapView } from 'store/viewsRoadmap-api';
import { deleteRoadmapViewAction, deleteRoadmapViewLoadingSelector } from 'store/viewsRoadmap-slice';

const classesInfo = classNames.bind(stylesInfo);
const classes = classNames.bind(styles);

interface HeaderProps {
    gridApi: GridApi<any> | null,
    updateQueryPreference: (value: string) => void,
    searchValue: string,
    emptyState: boolean
    totalRows: number
    pendingRows: number
    roadmapViewId: number | null
}

const Header: FC<HeaderProps> = ({
    gridApi,
    updateQueryPreference,
    searchValue,
    emptyState,
    totalRows,
    pendingRows,
    roadmapViewId
}) => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('')

    const deleteRoadmapViewLoading = useSelector(deleteRoadmapViewLoadingSelector)

    const updateQueryParam = (value: string) => {
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (!!value) {
            modifiedSearchParams.set('query', value)
        } else {
            modifiedSearchParams.delete('query')
        }

        setSearchParams(modifiedSearchParams, { replace: true });
        updateQueryPreference(value);
    }

    const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateQueryParam(e.target.value)
    }

    const handleSearchClear = () => {
        updateQueryParam('')
    }

    const showDeleteConfirmation = () => {
        setOpenConfirmation(true);
    };

    const actions: ButtonItem[] = [
        { label: 'Delete', action: showDeleteConfirmation, type: 'red' },
    ];

    const onCancelDelete = () => {
        setOpenConfirmation(false);
    };

    const onDeleteConfirmation = async () => {
        if (roadmapViewId) {
            try {
                await dispatch(deleteRoadmapView(roadmapViewId))
                dispatch(deleteRoadmapViewAction(roadmapViewId))
                setOpenConfirmation(false)

                setSuccessMessage('Analyses deleted successfully.')
                setTimeout(() => setSuccessMessage(''), 1500)
            } catch (err: any) {
                if (err?.data?.errorMessage) {
                    setError(err.data.errorMessage);
                } else {
                    setError('Failed to delete analyses');
                }
            }
        }
    };

    return (
        <Flexbox vertical className={classesInfo('headerContainer')}>
            <Flexbox vertical className={classesInfo('mb-6')}>
                <Flexbox wrap justifyBetween>
                    <Flexbox className={classesInfo('headerTitle')}>Planning Analysis & Action Items</Flexbox>
                    <Flexbox wrap align className={'gap-2'}>
                        {gridApi && (<ExportButton
                            api={gridApi}
                        />)}
                        {hasPermission(Actions.delete) && (
                            <ActionsMenu
                                buttonItems={actions}
                            />
                        )}
                    </Flexbox>
                </Flexbox>
                <Flexbox align className={classes('statusBox')}>
                    <Flexbox className={classes('statusBox-info')}><span>{pendingRows}</span>/<span>{totalRows}</span></Flexbox>
                    <Flexbox className={classes('statusBox-description')}>( Pending Action / Total Items )</Flexbox>
                </Flexbox>
            </Flexbox>

            {!emptyState && (
                <Flexbox className={classesInfo('headerInfo')}>
                    <Flexbox>
                        <SearchField
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            onClear={handleSearchClear}
                            placeholder='Search'
                            className={classesInfo('searchInput')}
                        />
                    </Flexbox>
                </Flexbox>
            )}
            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={onDeleteConfirmation}
                confirmButtonStyle="danger"
                title="Delete this analyses?"
                confirmButtonLoading={deleteRoadmapViewLoading}
            >
                <Flexbox>
                    Are you sure you want to delete this analyses?
                </Flexbox>
            </ConfirmationDialog>
            <CustomSnackbar open={!!successMessage} type='success' title={successMessage} />
            <CustomSnackbar open={!!error} type='error' title={error} />
        </Flexbox>
    )
}

export default Header